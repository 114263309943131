import React from "react"
import { graphql, Link } from "gatsby"
import classnames from "classnames"

import styles from "./testimonials.module.scss"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Container from "../components/container"
import Testimonials from "../components/testimonials"
import ButtonSet from "../components/buttonSet"

const TestimonialsPage = ({ data }) => (
  <Layout>
    <SEO
      title="Testimonials"
      description={data.site.siteMetadata.profile.purpose}
    />
    <Container>
      <div className="header align-center">
        <h1>Testimonials</h1>
        <p className={classnames("subtitle", "block-center", styles.subtitle)}>
          {data.site.siteMetadata.profile.purpose}
        </p>
      </div>

      <div>
        <Testimonials items={data.testimonials.edges} />
      </div>

      <ButtonSet
        render={buttonSetStyles => (
          <>
            <div className={buttonSetStyles.button}>
              <Link to="/contact" className="button">
                Get In Touch
              </Link>
            </div>
            <div className={buttonSetStyles.link}>
              <Link to="/about">About Ian Lunn</Link>
            </div>
          </>
        )}
      />
    </Container>
  </Layout>
)

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        profile {
          purpose
        }
      }
    }
    testimonials: allMarkdownRemark(
      sort: { order: ASC, fields: [frontmatter___order] }
      filter: {
        fileAbsolutePath: {
          regex: "/(?!testimonials/_archive)(?!testimonials/_empty)(testimonials)/"
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            name
            link
            organisation
            avatar {
              childImageSharp {
                fluid(maxWidth: 120) {
                  presentationWidth
                  presentationHeight
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`

export default TestimonialsPage
